// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-appointment-js": () => import("./../../../src/pages/appointment.js" /* webpackChunkName: "component---src-pages-appointment-js" */),
  "component---src-pages-blogs-do-you-need-a-perfect-butlers-pantry-with-your-dream-kitcken-js": () => import("./../../../src/pages/blogs/do-you-need-a-perfect-butlers-pantry-with-your-dream-kitcken.js" /* webpackChunkName: "component---src-pages-blogs-do-you-need-a-perfect-butlers-pantry-with-your-dream-kitcken-js" */),
  "component---src-pages-blogs-how-energy-star-rating-works-when-building-your-home-js": () => import("./../../../src/pages/blogs/how-energy-star-rating-works-when-building-your-home.js" /* webpackChunkName: "component---src-pages-blogs-how-energy-star-rating-works-when-building-your-home-js" */),
  "component---src-pages-blogs-js": () => import("./../../../src/pages/blogs.js" /* webpackChunkName: "component---src-pages-blogs-js" */),
  "component---src-pages-blogs-landscaping-ideas-for-your-newly-built-home-js": () => import("./../../../src/pages/blogs/landscaping-ideas-for-your-newly-built-home.js" /* webpackChunkName: "component---src-pages-blogs-landscaping-ideas-for-your-newly-built-home-js" */),
  "component---src-pages-blogs-security-measures-to-keep-you-safe-in-your-new-home-js": () => import("./../../../src/pages/blogs/security-measures-to-keep-you-safe-in-your-new-home.js" /* webpackChunkName: "component---src-pages-blogs-security-measures-to-keep-you-safe-in-your-new-home-js" */),
  "component---src-pages-blogs-top-winter-interior-trends-for-2019-js": () => import("./../../../src/pages/blogs/top-winter-interior-trends-for-2019.js" /* webpackChunkName: "component---src-pages-blogs-top-winter-interior-trends-for-2019-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-displays-js": () => import("./../../../src/pages/displays.js" /* webpackChunkName: "component---src-pages-displays-js" */),
  "component---src-pages-facades-js": () => import("./../../../src/pages/facades.js" /* webpackChunkName: "component---src-pages-facades-js" */),
  "component---src-pages-floorplans-js": () => import("./../../../src/pages/floorplans.js" /* webpackChunkName: "component---src-pages-floorplans-js" */),
  "component---src-pages-gallery-js": () => import("./../../../src/pages/gallery.js" /* webpackChunkName: "component---src-pages-gallery-js" */),
  "component---src-pages-gallery-photos-js": () => import("./../../../src/pages/gallery/photos.js" /* webpackChunkName: "component---src-pages-gallery-photos-js" */),
  "component---src-pages-gallery-videos-js": () => import("./../../../src/pages/gallery/videos.js" /* webpackChunkName: "component---src-pages-gallery-videos-js" */),
  "component---src-pages-get-started-js": () => import("./../../../src/pages/get-started.js" /* webpackChunkName: "component---src-pages-get-started-js" */),
  "component---src-pages-grand-studio-js": () => import("./../../../src/pages/grand-studio.js" /* webpackChunkName: "component---src-pages-grand-studio-js" */),
  "component---src-pages-homedesigns-js": () => import("./../../../src/pages/homedesigns.js" /* webpackChunkName: "component---src-pages-homedesigns-js" */),
  "component---src-pages-inclusions-bronze-js": () => import("./../../../src/pages/inclusions/bronze.js" /* webpackChunkName: "component---src-pages-inclusions-bronze-js" */),
  "component---src-pages-inclusions-platinum-js": () => import("./../../../src/pages/inclusions/platinum.js" /* webpackChunkName: "component---src-pages-inclusions-platinum-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-inspection-js": () => import("./../../../src/pages/inspection.js" /* webpackChunkName: "component---src-pages-inspection-js" */),
  "component---src-pages-policy-disclaimer-js": () => import("./../../../src/pages/policy/disclaimer.js" /* webpackChunkName: "component---src-pages-policy-disclaimer-js" */),
  "component---src-pages-policy-privacy-js": () => import("./../../../src/pages/policy/privacy.js" /* webpackChunkName: "component---src-pages-policy-privacy-js" */),
  "component---src-pages-request-callback-js": () => import("./../../../src/pages/request-callback.js" /* webpackChunkName: "component---src-pages-request-callback-js" */),
  "component---src-pages-reviews-js": () => import("./../../../src/pages/reviews.js" /* webpackChunkName: "component---src-pages-reviews-js" */),
  "component---src-pages-services-js": () => import("./../../../src/pages/services.js" /* webpackChunkName: "component---src-pages-services-js" */),
  "component---src-templates-display-jsx": () => import("./../../../src/templates/Display.jsx" /* webpackChunkName: "component---src-templates-display-jsx" */),
  "component---src-templates-facade-jsx": () => import("./../../../src/templates/Facade.jsx" /* webpackChunkName: "component---src-templates-facade-jsx" */),
  "component---src-templates-floorplan-jsx": () => import("./../../../src/templates/Floorplan.jsx" /* webpackChunkName: "component---src-templates-floorplan-jsx" */),
  "component---src-templates-home-design-jsx": () => import("./../../../src/templates/HomeDesign.jsx" /* webpackChunkName: "component---src-templates-home-design-jsx" */),
  "component---src-templates-range-jsx": () => import("./../../../src/templates/Range.jsx" /* webpackChunkName: "component---src-templates-range-jsx" */),
  "component---src-templates-review-jsx": () => import("./../../../src/templates/Review.jsx" /* webpackChunkName: "component---src-templates-review-jsx" */)
}

